.Lines input{
width: 100%;
padding: 10px;

border: 1px solid #bcbcbc;
margin-top: 15px;
}
.nameInitial textarea{
    width: 100%;
    
}
.file label{
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    border: none;
    border-radius: 4px;
    border-width: 1px;
    background-color: #B88E2F;
    color: #fff;
    padding: 10px;
    display: inline-block;
}

.file {
    margin-bottom: 15px;
}
   
