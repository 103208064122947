@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  font-weight: 500;
  font-size: 16px;
  color: #333;
}

.App {
  display: flex;
  justify-content: center;
}
h1,
h2,
h3 {
  font-weight: 600;
}

h1 {
  font-size: 40px;
  line-height: 1.1;
  margin-bottom: 15px;
}

h2 {
  font-size: 30px;
  line-height: 1.1;
}

h3 {
  font-size: 22px;
  line-height: 1.1;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  font-size: 16px;
}

.container {
  max-width: 90%;
  margin: 0 auto;
}

.gap {
  padding: 50px 0;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.space-bw {
  justify-content: space-between;
}

.al-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-start {
  align-items: flex-start;
}

.text-center {
  text-align: center;
}

.col-25 {
  width: 24%;
}

.col-33 {
  width: 33.33%;
}

.col-33_2 {
  width: 32.33%;
}

.col-40 {
  width: 39%;
}

.col-50 {
  width: 49%;
}

.col-60 {
  width: 59%;
}

.pad-top {
  padding-top: 50px;
}

.common-text {
  margin-bottom: 20px;
}

.lpad {
  padding-left: 50px;
}

.pos-relate {
  position: relative;
}
.mb{
  margin-bottom: 15px;
}
/* header css */
header {
  background: #fff;
}
.nav-pages ul li{
 color: #565E6C;
  font-size: 18px;
  margin-left: 20px;
  position: relative;
  transition: color 0.3s ease;
  padding: 10px 15px;
  cursor: pointer;
}
.icon-access{
  background: #636AE8;
  border-radius: 5px;
  
}
.icon-access p{
  padding: 7px;
  color: #fff !important;
  font-size: 14px;
  cursor: pointer;
}


.nav-pages ul li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #636AE8; 
  transition: width 0.3s ease;
}

.nav-pages ul li:hover::after {
  width: 100%;
}


.top-area {
  padding: 15px 0;
}

.top-area a {
  font-size: 22px;
  font-weight: 700;
}

.top-area p {
  color: #1e1e1e;
}

.top-area .nmbr {
  font-size: 16px;
  font-weight: 500;
  color: #8e8e8e;
}
.cart-header{
  display: flex;
  flex-wrap: wrap;

}
nav {
  background: #FCF8F3;
  padding: 10px 0;
}

nav ul li {
  margin: 0 30px;
}

.toggle {
  display: none;
}

.toggle i {
  font-size: 18px;
}
/* footer */
footer h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

footer ul li {
  margin-bottom: 10px;
}

footer ul li a {
  font-size: 14px;
  color: #000;
}

footer form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

footer form input[type="text"],
footer form input[type="button"] {
  width: 73%;
  outline: none;
  border: none;
  padding: 5px;
  border-bottom: 2px solid #9F9F9F;
}

footer form input[type="button"] {
  width: 26%;
  background: transparent;
}

footer .flex {
  border-bottom: 1px solid #D9D9D9;
}

footer .copyright p {
  padding-top: 15px;
  font-size: 14px;
}

footer{
  background-color: #f8f9fd;
}
/* Target the scrollbar */
::-webkit-scrollbar {
  width: 6px;  
  height: 6px; 
}

/* Background of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}


::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px; 
}


::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* @media(max-width:1023px) {
  .container {
    max-width: 95%;
  }
} */

@media(max-width: 767px) {

  .col-50,
  .col-40,
  .col-60 {
    width: 100%;
  }

  .col-25 {
    width: 49%;
  }

  .col-33 {
    width: 100%;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .gap {
    padding: 30px 0;
  }

  .lpad {
    padding-left: 25px;
  }
  .top-area a {
    font-size: 16px;
}

.tagline {
    display: none;
}

.top-area .nmbr {
    font-size: 14px;
}

.toggle {
    display: block;
}

nav {
    position: absolute;
    background: #FCF8F3;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 55px;
    left: 0;
    display: none;
    padding: 0;
}

nav ul {
    display: inline-block !important;
}

nav ul li {
    margin: 15px 0px;
    padding: 10px 0;
}

.no-show {
    display: none;
}
}

@media(max-width:480px) {
  .banner-area {
    padding: 30px 0;
  }

  h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
  }

  .common-text {
    margin-bottom: 10px;
  }

  .col-25 {
    width: 100%;
  }

  .gap {
    padding: 20px 0;
  }

  .lpad {
    padding-left: 0;
  }
  footer h3 {
    font-size: 16px;
    margin-bottom: 10px;
}

footer ul li a {
    font-size: 14px;
}

footer .col-25 {
    margin-bottom: 10px;
}
}

@media (max-width:967px){
  .col-25 {
    width: 50%;
  }
  .mrg-top{
    margin-top: 20px;
  }
  h1 {
    font-size: 30px;
}
}

@media (max-width:767px){
  .col-25 {
    width: 100%;
  }

}

@media (max-width:480px){
  .col-33_2 {
      width: 100%;
      margin-bottom: 20px;
  }
}