.icon-1,.icon-2{
    margin: 10px 10px;
}
.icon-2 p{
    margin-left: 8px;
}
.icon-2 :nth-child(2){
    font-size: 12px;
}
.icon-2{
    cursor: pointer;
}
.wholesale-heading p{
    font-size: 25px;

}
.wholesale-heading img{
    width: 105px;
}
@media (max-width:480px){
    .icon-1, .icon-2 {
        margin: 0px 0px;
    }
}
