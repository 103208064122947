.card-container{
    width: 30.75%;
    border-radius: 0px;
    background: #f8f7fd;
    margin-bottom: 30px;
}
.journal-cards{
    margin-top: 20px;
}
.card-image img {
    margin: 0;
    width: 100%;
    display: block;
    box-shadow: none;
    height: 250px;
    object-fit: contain;
}
.card-content{
    padding: 10px;
    
}
.card-name p,.card-price p{
    padding-bottom: 8px ;
    font-size: 16px;
    font-weight: bold;
}
.card-btn button{
    border-radius: 3px;
    color: #fff;
    padding: 12px 15px;
    margin: 3px;
    font-size: 15px;
    background: #636AE8;
    border: none;
}

@media (max-width:1024px){
    .card-container {
        width: 50%;
    }
}

@media (max-width:968px){
    
    .card-container {
        width: 50%;
    }
}

@media (max-width:768px){
    
    .card-container {
        width: 100%;
    }
    .default-sorting select {
        width: 90%;
        margin-top: 15px;
    }
}



