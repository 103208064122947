.caseContainer {
    gap: 2.5rem;
    height: 100vh;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-width: 0
}

.cesdk {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.75rem;
}

.wrapper {
    border-radius: 0.75rem;
    flex-grow: 1;
    min-height: 450px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), 0 18px 18px -2px rgba(18, 26, 33, 0.12), 0 8px 8px -2px rgba(18, 26, 33, 0.12), 0 4px 4px -2px rgba(18, 26, 33, 0.12);
}

.link {
    text-decoration: underline
}

.airtable {
    background: transparent;
    border: 1px solid #ccc;;
    border-radius: 12px;
    flex-grow: 1
}