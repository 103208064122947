.mainContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  
   
}
:root {
  --primary-color: #000000;
  --header-text-color: #fff;
}

/* Typograhpy */
:root {
  --font-heading: "Epilogue", serif;
  --font-main: -apple-system, BlinkMacSystemFont, "Raleway", "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}



.site-identity p.site-title,
h1.site-title a,
h1.site-title,
p.site-title a,
.site-branding h1.site-title a {
  font-family: "Lato", serif;
}

p.site-description {
  font-family: "Raleway", serif !important;
}

.accordion-content {
  width: 40%;
  margin-left: 5rem;
  margin-top: 20px;
  height: 584px;
  overflow-y: auto;
}

.swatch label {
  cursor: pointer;
  display: inline-block;
  background: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}
.swatch img{
  border-radius: 50%;
}
.swatch input{
  display: none;
}
.swatch-container {
  display: flex;
  flex-wrap: wrap;
}
.shapesDrop{
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  margin-bottom: 10px;
  outline: none;
  box-shadow: 0 1px 3px rgba(0,0,0,.23) inset;
  background: #fff;
  position: relative;
  height: 44px;
}
.shapesDrop h4{
  font-weight: 400;
}
.shapesDrop .shapes,.shapesDrop .fonts, .shapesDrop .backcol, .shapesDrop .textcol {
  position: absolute;
  top: 48px;
  left: 0;
  display: none;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.shapesDrop svg {
  position: absolute;
  top: 13px;
  right: 5px;
}
.shapesDrop .shapes .swatch label {
  cursor: pointer;
  display: inline-block;
  background: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}
.shapesDrop .fonts .swatch label {
  cursor: pointer;
  display: inline-block;
  background: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}
/* hover case */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the label */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  width: 120px;
  font-family: Arial, Helvetica, sans-serif;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%; /* Position the arrow at the bottom of the tooltip box */
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent; /* Upward-pointing arrow */
}

.popular-heading h2{
  margin-bottom: 45px;
  font-size: 33px;
 
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.home-layout{
  justify-content: center;
}

.customcanvas{
  width: 40%;
}


.accordion-content select{
  /* width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #000;
  padding: 10px 10px 4px 0;
  margin-bottom: 15px;
  font-size: 14px; */
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  margin-bottom: 10px;
  outline: none;
  box-shadow: 0 1px 3px rgba(0,0,0,.23) inset;

}

.personalisethisproductheading h2{
  font-size: 14px;
  margin-bottom: 10px;
}

.more-cust h4{
  font-size: 14px;
  margin-bottom: 8px;

}
.more-cust {
  margin-bottom: 15px;
  margin-top: 15px;
}
/* .more-cust-1{
  margin-bottom: 15px;
  margin-top: 15px;
} */
.more-cust-1 h4{
  font-size: 14px;
  margin-bottom: 8px;
}
.more-btn button{
  padding: 10px;
  background: #B88E2F;
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  border: none;
  border-radius: 4px;
  border-width: 1px;
  width: 50%;
  
}
.more-btn button a{
  color: #fff;
}
.more-btn{
  margin-bottom: 15px;
}
.accordion-header{
  cursor: pointer;
  padding: 15px;
  border: 1px solid #c4c4c4;
  outline: none;
  width: 100%;
  text-align: left;
  font-size: 16px;
  transition: background-color 0.2s ease;
  border-radius: 30px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,.23) inset;
}
.accordion-header h3{
  font-size: 1em;
  display: block;
  font-weight: 600;
}
.coverimage{
  display: block;
  width: 150px;
  height: 250px;
  margin-top: 10px;
  object-fit: cover;
}
.coverimage img{
  width: 100%;
}
.coverimage1{
  display: block;
  width: 150px;
  height: 250px;
  margin-top: 10px;
  object-fit: cover;
  margin-left: 25px;
}
.coverimage1 img{
  width: 100%;
}
.customisecart input{
  width: 3.631em;
  padding: 12px 15px;
  border: 1px solid #000;
}
.customisecart button{
  padding: 12px 15px;
  color: #fff;
  border: none;
  font-size: 15px;
  background: #4a55b4;
  border-radius: 5px;
}
.addbtn{
  margin-left: 10px;
 
 
}


/* modal css */
/* Modal.css */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Background color with opacity */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.images-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.modal-image {
  width: 200px;
  height: auto;
}
/* tab css */
.tab-content {
  display: none;
  padding: 10px;
}

.tab-head {
  margin-bottom: 15px;
  border-bottom: 1px solid #000;
}

.tab-head li {
  display: inline-block;
  /* padding: 0px 15px; */
  position: relative;
  cursor: pointer;
  /* border-radius: 5px; */
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #efefef;
  margin-right: 10px;
}

.tab-head li.active {
  background: #4a55b4;
  color: #fff;
}

.tab-content {
  display: block;
}
.rating-container {
  display: flex;
  gap: 5px;
}

.star {
  font-size: 30px;
  cursor: pointer;
  color: grey; /* Initial background is white */
  transition: color 0.3s ease;
  
}

.star.selected {
  color: gold; /* Change to gold when selected */
}
.review-form textarea{
  width: 100%;
}
.rating-name, .rating-remaining{
  width: 48%;
}
.rating-name input, .rating-remaining input{

  width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.rating-save{
  margin-top: 15px;
}
.submitbtn{
  margin-top: 15px;
}
.submitbtn button{
  padding: 12px 15px;
  color: #fff;
  font-size: 15px;
  background: #B88E2F;
  border: none;
  border-radius: 5px;
}
.related-products{
  margin-top: 4rem;
  margin-bottom: 25px;
}
.related-cards{
  margin-top: 10px;
}
/* toggle css */
.toggleclass {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  padding: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,.23) inset;
  background: #fff;
}
.toggleclass h3{
  font-size: 14px;
}
.toggleclass:hover{
  background-color: #e0e0e0;
}
.dropdown {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown ul li {
  padding: 8px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown ul li:hover {
  background-color: #f0f0f0;
}

.covertype label{
  font-size: 14px;
}
.dropdown ul li img{
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.customise-tabs{
  margin-top: 6rem;
}

.input-text input{
  width: 100%;
  padding: 10px;
  border: 1px solid #bcbcbc;
  font-size: 14px;
  outline: none;
}
/* .inner-content{
  background: rgb(249, 249, 249);
} */

.customise-img1{
  width: 30%;
}

.customise-img1 img {
  width: 100%;
}

/* down images popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85); /* Darker background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content centered in the screen */
.popup-content {
  background: transparent;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  width: 90%;
  height: 90%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.popup-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1001; 
}

.qty-box{
  width: 13%;
}
.qty-box input{
  width: 100%;
}
.number-pages input{
  width: 80%;
  padding: 5px;
  margin-top: 10px;
}
.number-pages{
  margin-bottom: 10px;
}
@media (max-width:768px){
  .customcanvas{
      width: 100%;
  }
  .accordion-content{
      width: 100%;
      
  }
  }
