.quantity-box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 143px;
  height: 48px;
}
  
  .quantity-box button {
    background:none;
    color: #000;
    border: none;
    width: 50px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
  }
  
 .quantity-box button:hover {
    background-color: #0056b3;
  }
  
  .quantity-box input {
    width: 40px;
    text-align: center;
    border: none;
    font-size: 16px;
    margin: 0 10px;
  }
  .product{
    border: 1px solid #000;
  }
  .product-content{
    padding: 10px;
  }
  .product-upper{
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
  }
  .product-upper p{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .product-image{
    width: 20%;
    display: flex;
  }
  .product-image img{
    width: 50%;
    height: 50%;
    margin-right: 5px;
  }

  .product-description {
    width: 60%;
  }
.product-description p u{
  font-size: 20px;
  font-weight: bold;
}
.product-description p{
  margin-bottom: 15px;
}
.product-description p span{
  color: #000;
  text-decoration: underline;
  font-size: 18px;
}
.quantity-box{
  margin-bottom: 10px;
}
.product-down{
  padding: 10px;
}
.details-section{
  margin-top: 25px;
}
.product{
  width: 70%;
}
.cart-total{
  width: 25%;
}
.cart-heading{
  border-bottom: 1px solid hsla(0, 0%,
  7%,.11);
  
}
.cart-heading h3{
  margin-bottom: 10px;
}
.addcoupon{
  padding: 10px;
}
.addcoupon-heading{
  margin-bottom: 10px;
}
.entercode input{
  width: 70%;
  margin-bottom: 15px;
  padding: 10px;

}
.entercode button{
  width: 29%;
  margin-bottom: 15px;
  background: #B88E2F;
  border: none;
  border-radius: 5px;
  color: #fff;
  
}
.entercode{
  border-bottom: 1px solid hsla(0, 0%,
  7%,.11);
}
.subtotal{
  margin-top: 10px;
  margin-bottom: 10px;
}

.free-shipping{
  margin-bottom: 10px;
  border-bottom: 1px solid hsla(0, 0%,
  7%,.11);

}
.free-shipping p{
  margin-bottom: 10px;
}

.remove-item{
  cursor: pointer;
}
.empty-cart{
  text-align: center;
}
.empty-cart i{
  font-size: 44px;
  margin-bottom: 20px;
  color: #000;
}
.empty-cart p{
  margin-bottom: 20px;
}
.empty-cart .button{
  background: #B88E2F;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 5px;
}
.checkout-btn button{
  width: 100%;
  margin-top: 15px;
  background:#B88E2F ;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 40%;
  height: 600px;
  overflow-y: auto;
  position: relative;
}

.popup button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #45a049;
}
.cartcoupon{
  background: #1A3A3A;
  color: #fff;
  text-align: center;
  padding: 15px 30px 6px 30px ;
  border-radius: 15px;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.15);
  position: relative;
  margin-top: 20px;
 
}
.cartcoupon-row{
 
  margin: 18px auto;
  width: fit-content;
  color: #fff;

}
.cartcircle-1,.cartcircle-2{
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
}
.cartcircle-1{
  left: -25px;
}
.cartcircle-2{
  right: -25px;
}
.coupon-left{
  width: 29%;
  display: flex;
  border-right: 5px dotted #fff;
  line-height: 170px;
}
.coupon-left p{
  rotate: -90deg;
  font-size: 22px;
  
}
.coupon-right{
  width: 70%;
}
.cartcoupon-row button{
  width: 50%;
  margin-top: 15px;
}
.coupon-right h3{
  font-size: 28px;
}
.cartcoupon-row p{
  font-size: 13px;
}
.check-coupon button{
  background: #B88E2F;
  border: none;
  width: 100%;
  padding: 10px;
  color: #fff; 
  border-radius: 10px;
}

@media (max-width:768px){
  .product {
    width: 100%;
}
.cart-total{
  width: 100%;
  margin-top: 20px;
}

}