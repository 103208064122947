.coupon-card{
    background: #636AE8;
    color: #fff;
    text-align: center;
    padding: 20px 40px;
    border-radius: 15px;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.15);
    position: relative;
    width: 30%;

}
.logo{
    width: 80px;
    border-radius: 8px;
    margin-bottom: 20px;

}
.coupon-card h3{
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;

}
.coupon-card p{
    font-size: 15px;

}
.coupon-row{
    display: flex;
    align-items: center;
    margin: 25px auto;
    width: fit-content;
    background: #fff;

}
#cpnCode{
    border: 1px dashed #fff;
    padding: 10px 20px;
    border-right: 0;

}
#cpnBtn{
    border: 1px solid #fff;
    background: #fff;
    padding: 10px 20px;
    color: #7158fe;
    cursor: pointer;
}
.circle1, .circle2{
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}
.circle1{
    left: -25px;
}
.circle2{
    right: -25px;
}

@media (max-width:968px){
    .coupon-card {
        width: 50%;
    }
}

@media (max-width:767px){
    .coupon-card {
        width: 100%;
    }
}