.login-section{
    background-color: #fcf8f3;
  margin: 0;
  padding: 0;
 
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-card {
    display: flex;
    width: 70%;
    margin: 2rem 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .login-card-left {
    flex: 1;
  }
  
  .login-card-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .login-card-right {
    flex: 1;
    padding: 40px;
    background-color: #f9f9f9;
  }
 .login-card-right h1{
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
 }

  
  .input-group {
    margin-bottom: 20px;
  }
  
  .login-card label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .login-card input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-card button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .login-card p{
    margin-top: 15px;
  }
 