.journal-section {
    width: calc(100% - 350px);
}
.default-sorting select {
    background: #fff;
    color: #000;
    padding: 10px;
    outline: none;
    border: 1px solid #e3e3e3;
}
/* pagination css */
.pagination-controls{
   
    padding: 10px;
    width: 45%;
    margin: 2rem auto;
}

.no-data{
    margin-top: 3rem;
}
.no-data p{
    font-size: 25px;
}

@media (max-width:767px){
    .sidebar-section{
        width: 100%;
        margin-bottom: 30px;
    }
    .journal-section {
        width: 100%;

    }
    h1 {
        font-size: 25px;
        margin: 15px 0;
    }
}

@media (max-width:768px){
    .sidebar{
        width: 100%;
        margin-bottom: 20px;
    }
}